import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";

import { configurationPageTabName, routes } from "../../constants/navigation";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(1),
  },
}));

export default function ConfigurationPage({ match, history }) {
  const classes = useStyles();
  const [tab, setTab] = useState<configurationPageTabName>();

  useEffect(() => {
    if (match.params.tab) {
      setTab(match.params.tab);
    } else {
      history.push(routes.configurationPricebookListing);
    }
  }, [history, match.params.tab]);

  if (!tab) {
    return null;
  }

  return (
    <>
      <Card className={classes.card}>
        <CardHeader title="Configuration" />
        <Divider />
        <AppBar enableColorOnDark position="static" color="inherit">
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_event, newTab) => {
              if (tab !== newTab) {
                setTab(newTab);
              }
            }}
            scrollButtons={false}
            variant="scrollable"
          >
            <Tab
              key={configurationPageTabName.pricebook}
              value={configurationPageTabName.pricebook}
              label="Pricebook"
              component={Link}
              to={routes.configurationPricebookListing}
            />
            <Tab
              key={configurationPageTabName.partnerBillingRules}
              value={configurationPageTabName.partnerBillingRules}
              label="Partner Billing Rules"
              component={Link}
              to={routes.configurationPartnerBillingRules}
            />
          </Tabs>
        </AppBar>
      </Card>
    </>
  );
}
